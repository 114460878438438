import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import PhpServiceBanner from '../images/php_service_banner.svg'
import PhpServiceFeature from '../images/php_service_features.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import ServiceCircleShape from '../images/half_circle_service_page.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import 'lazysizes'

const PhpDevelopment = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/php-web-development-services" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="PHP Web Development Services" description='eWise - PHP Web Development Services'/>
 <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative"><strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-90 block">PHP Web</strong> <span className="relative top-m-10 md:top-m-10 lg:top-m-15 2xl:top-m-37 inline-block md:pl-0 xl:pl-10 2xl:pl-14 text-gray text-2xl md:text-3xl lg:text-32 xl:text-34 2xl:text-46">Development Services</span> <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-40 xl:top-m15 2xl:top-m32 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4 sm:pt-0">
                        <div className="inner relative xl:p-12 2xl:p-0">
                            <img className="w-full sm:w-auto max-w-400 md:max-w-420 lg:max-w-470 m-auto lazyload" loading="lazy" data-src={PhpServiceBanner} alt="Banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>
  <section className="relative">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content pb-10 md:pb-12 lg:pb-16 xl:pb-24 px-4 xl:pr-16 2xl:pr-32">
                <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-35 2xl:text-40 ">PHP - Web applications with faster turn-around time!<span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <p>Get your business web applications ready within a few days! Create sophisticated web applications for your users with this open-source platform. This web development platform assures the security one needs while distributing applications at remote locations. Leverage one of the admired and popular server side scripting languages to create the stunning business applications.</p>
            </div>

          </div>
      </div>
  </section>
  <section className="relative pb-8 sm:pb-10 lg:pb-14">
      <div className="container">
          <div className="-mx-4">
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full lg:w-1/2 px-4 order-2 lg:order-1">
                 
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why eWise thinks PHP is almost perfect for web <br class="hidden xl:inline-block 2xl:hidden"></br>development!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Seamlessly integrate your custom CMS platforms with PHP</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Cost-effectively integrate your website with existing infrastructure</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Extensively customize your website with the one of a kind flexibility</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Get abundant features and add-ons for an optimized user experience</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Create highly dynamic and responsive websites with ability to embed in HTML codes</li>
                        <li className="pl-10 relative">Simplify the data processing ability with standard libraries available</li>
                    </ul>
                </div>
                <div className="features_right w-full lg:w-1/2 flex justify-center px-4 order-1 lg:order-2">
                    <img className="object-contain max-w-330 md:max-w-420 lg:max-w-420 xl:max-w-420 2xl:max-w-420 mb-5 lg:mb-0 w-full lazyload" loading="lazy" data-src={PhpServiceFeature} alt="iphone"/>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">A few more features at a glance<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Simplicity</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">It is a very simple language to code which makes it even easier to understand and modify.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Speedy T-A-T</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">It offers exceptionally fast turnaround time as it supports an extensive large family of PHP-frameworks.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Cost-effective</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">End-up paying for customizations and feature development and not the platform itself!</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Large support community</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">You don’t have to run back to your developers for small problems. There is a large growing community to support you.</li>
                    </ul>
                </div>
            </div>

            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise offers extensive services for PHP Development<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">App Consultation</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Get a consultation and guidance to design and develop the right kind of business application to serve your purpose. We offer end-to-end consultation services.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">UI/UX Design</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise can develop creative and optimized user interfaces and experience using PHP as a platform. Our dedicated design experts make sure we get the design right to suit your business.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">App Integration</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">We can help you with seamless and flawless interaction of your multiple applications and modules. We can integrate PHP apps into various business infrastructures to increase efficiency.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">App Development</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise can develop a business application for you right from scratch. Our in-house experts are well-versed with PHP technology and develop the best apps in the market.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Feature Development</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Do you just need a particular feature to be developed for an existing PHP web platform to amplify your user experience? We will do it and integrate it seamlessly with your web application.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Maintenance and support</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">We can help you to support and maintain your PHP web applications. Keep your focus on your business; leave your technical issues to us!</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why hire eWise as PHP expert!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="px-4 pb-4">PHP is a web app development platform for businesses who want to quickly launch their applications and enable you to engage your users through multiple platforms!</p>
                    <p className="px-4 pb-4">PHP is a popular web platform for web application development and if you are thinking of choosing it, let us know! eWise is a team of professionals who can enable you to realize your business goals and strategies. We ensure you get the deliverables in your timeline and budget, both. For more details and information on PHP or web development, contact us today.</p>
                    <p className="px-4">We promise to get your customized PHP web application started to meet your business goals.</p>
                </div>
            </div>
          </div>
      </div>
      <img className="absolute right-0 bottom-1/4 z-1 max-w-150 md:max-w-180 lg:max-w-200 xl:max-w-250 2xl:max-w-320" src={ServiceCircleShape} alt="Service Circle"/>
  </section>
    </Layout>
  )
}

export default PhpDevelopment